
const TechData = 
    [
        {
          image: "https://grorapidassets.s3.amazonaws.com/images/webinartemplate/Travel Agency/men1.png",
          name: "Dheeraj Sharma",
          title: "Android developer",
          des: "Analytical and detail oriented Android app developer professional with stellar communication skills. Adept at bringing all team members together to reach a common goal on time and under budget. Conceptualizing app solutions with the latest technology, design theory, and a large dose of creativity. Committed to viable and easily functional app solutions for clients.",
          icon: "https://grorapidassets.s3.amazonaws.com/images/webinartemplate/Travel Agency/Icon Color.png",
         delay:"0.2s",
        },
        {
          image: "https://grorapidassets.s3.amazonaws.com/images/webinartemplate/Travel Agency/men1.png",
          name: "Dheeraj Sharma",
          title: "Android developer",
          des: "Analytical and detail oriented Android app developer professional with stellar communication skills. Adept at bringing all team members together to reach a common goal on time and under budget. Conceptualizing app solutions with the latest technology, design theory, and a large dose of creativity. Committed to viable and easily functional app solutions for clients.",
          icon: "https://grorapidassets.s3.amazonaws.com/images/webinartemplate/Travel Agency/Icon Color.png",
        delay:"0.4s",
        },  {
          image: "https://grorapidassets.s3.amazonaws.com/images/webinartemplate/Travel Agency/men1.png",
          name: "Dheeraj Sharma",
          title: "Android developer",
          des: "Analytical and detail oriented Android app developer professional with stellar communication skills. Adept at bringing all team members together to reach a common goal on time and under budget. Conceptualizing app solutions with the latest technology, design theory, and a large dose of creativity. Committed to viable and easily functional app solutions for clients.",
          icon: "https://grorapidassets.s3.amazonaws.com/images/webinartemplate/Travel Agency/Icon Color.png",
         delay:"0.6s",
        },
      
]

export default TechData;