import React from 'react'
// import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
const WhatsApp = () => {
  return (
//     <WhatsAppWidget
//     phoneNo="9761955118"  

// />
<></>
  )
}

export default WhatsApp